.title {
  font-weight: 600;
  margin-right: 4px;
}

.type {
  margin-right: 4px;
  font-style: italic;
}

.metaInfo {
  color: rgba(0, 0, 0, 0.54);
}

.piece {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.description,
.attribution,
.composerNotes {
  margin-bottom: 16px;
}

.details {
  .divider {
    margin-bottom: 8px;
  }
}

.detailLink{
  display: flex;
  align-items: center;
  
  .linkIcon {
    margin: 0 4px 0 2px;
    transition: opacity 0.3s ease;
    font-size: 1rem;
  }
}
